export const KLARNA_MAX_IN_EUROS = 5000;
export const KLARNA_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'DE', // Germany
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'IE', // Ireland
  'IT', // Italy
  'NL', // Netherlands
  'PT', // Portugal
];
