import { Avatar, Button } from '@design-system';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { buildSignInFlow } from '../../../features/auth';
import { AuthEntryPoint, AuthFlow } from '../../../features/auth/types';
import { pathFor } from '../../../helpers/links';
import { useAuth, useFlow } from '../../../providers';
import { TrackNavbarLinkClicked } from '../../../utils/analytics';
import { AnalyticsObjectProvider } from '../../../utils/analytics/object';
import { ClickOutside } from '../../ClickOutside';

interface Props {
  isDarkMode?: boolean;
}

export const AccountNavigationItem: React.FC<Props> = () => {
  const { t } = useTranslation('navigation');
  const [showDropdown, setShowDropdown] = useState(false);
  const { isLoggedIn, logout, user } = useAuth();
  const { startFlow } = useFlow();
  const { pathname } = useRouter();

  const handleLogout = () => void logout();

  return (
    <AnalyticsObjectProvider name="account menu" sendViewedEvent={false}>
      {(emitter) => (
        <>
          {isLoggedIn && user ? (
            <ClickOutside
              className="relative flex"
              onClickOutside={() => {
                // Only fire this event if we're closing the dropdown
                if (showDropdown) emitter('close', 'clicked');

                setShowDropdown(false);
              }}
            >
              <button
                className="cursor-pointer"
                onClick={() => {
                  emitter(showDropdown ? 'close' : 'open', 'clicked');
                  setShowDropdown(!showDropdown);
                }}
                type="button"
              >
                <Avatar
                  filename={user.avatar || undefined}
                  label={user.username || user.email || ''}
                />
              </button>

              {showDropdown && (
                <div className="ms:top-12 absolute right-0 top-10 w-44 divide-y divide-divider/20 rounded-sm border border-divider/20 bg-white shadow-card">
                  <ul className="p-2">
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_OVERVIEW' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'overview',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.account')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_DETAILS' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'personal details',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.personalDetails')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_ONBOARDING' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'interests',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.interests')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_ORDERS' })}
                        onClick={() => {
                          emitter('option', 'clicked', { title: 'orders' });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.orders')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_DRAW_ENTRIES' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'draw entries',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.drawEntries')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_ADDRESSES' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'addresses',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.addresses')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="block cursor-pointer rounded-sm px-2 py-2 hover:bg-neutral-1"
                        href={pathFor({ page: 'ACCOUNT_PAYMENT_METHODS' })}
                        onClick={() => {
                          emitter('option', 'clicked', {
                            title: 'payment methods',
                          });
                          setShowDropdown(false);
                        }}
                      >
                        {t('profile.dropdown.paymentMethods')}
                      </Link>
                    </li>
                  </ul>
                  <ul className="p-2">
                    <li>
                      <button
                        className="w-full cursor-pointer rounded-sm px-2 py-2 text-left hover:bg-neutral-1"
                        onClick={() => {
                          emitter('option', 'clicked', { title: 'sign out' });
                          handleLogout();
                        }}
                        type="button"
                      >
                        {t('profile.dropdown.signOut')}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </ClickOutside>
          ) : (
            <Button
              hideLabel
              icon="ic_account"
              label="Sign In"
              onClick={() => {
                TrackNavbarLinkClicked({
                  context: 'signin',
                  title: t('signin'),
                });
                startFlow({
                  name: AuthFlow.SignIn,
                  steps: buildSignInFlow({
                    optInGeneral: false,
                    skipProfileStep: pathname === '/aml',
                  }),
                  analyticsProperties: { source: AuthEntryPoint.NavBar },
                });
              }}
              size="sm"
              variant="transparent"
            />
          )}
        </>
      )}
    </AnalyticsObjectProvider>
  );
};
